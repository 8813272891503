<template>
  <div v-if="canAccess('rp_resume_payments')" class="card">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="p-col-12">
      <h6>Informe Resumen de Pagos</h6>
    </div>
    <div class="p-col-12">
      <div class="p-text-right p-fluid  p-ml-2">
        <div class="p-grid p-fluid  p-ml-2">
          <div class="p-col-12 p-md-1 p-mt-2" style="padding-top: 20px;">
                    <span class="p-float-label df" style="margin-right: 1px;">
                      <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"
                                @change="selectedMonth=null;selectedWeek=null;subsidiary=null;itemsTotal=[];filter()"
                      />
                      <label for="country">País</label>
                  </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
                <span class="p-float-label df">
                    <Dropdown id="subsidiary" v-model="subsidiary" :options="subsidiaries"
                              @change="itemsTotal=[]"
                              optionLabel="name"/>
                    <label for="subsidiary">Filial</label>
                </span>
          </div>

          <div class="p-col-12 p-md-1 p-mt-2" style="padding-top: 20px;">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedMonth" :options="months" optionLabel="month_process"
                                                 @change="filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
          </div>
          <div class="p-col-12 p-md-1 p-mt-2" style="padding-top: 20px;">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedWeek" :options="weeks" optionLabel="week_process"
                                                 @change="filter()"/>
                                        <label for="weeks">Semana</label>
                                    </span>
          </div>


          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df" style="margin-right: 3px">
                          <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                                    id="start_date" :showIcon="true" v-model="date_start"
                                    dateFormat="yy-mm-dd"
                                    :yearNavigator="true" yearRange="2019:2040"/>
                          <label for="start_date">Fecha Inicio</label>
            </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Buscar" class="p-button-success  df"
                    @click="getData()"/>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Exportar" class="p-button-secondary df"
                    @click="exportReport('XLS')"/>

          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Imprimir" class="p-button-info df"
                    @click="exportReport('PDF')"/>

          </div>

        </div>
      </div>
    </div>


    <div class="card" v-for="(resumePaymentContent, keyResumePayment) in resumePayments" :key="keyResumePayment">
      <div class="table-responsive">
        <table class="table table-hover" style="width: 100%">
          <thead>
          <tr>
            <td class="text-center" colspan='3'><h5><b>{{keyResumePayment}}</b></h5></td>
            <td class="text-center" colspan='1' ><b>{{resumePaymentContent.length}}</b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'total') }}</small></b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'others_values') }}</small></b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'accrued') }}</small></b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'credits') }}</small></b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'debits') }}</small></b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'net_to_pay') }}</small></b></td>
            <td class="text-center tdDescription"><b><small>{{ sumTotalResumePayments(resumePaymentContent,'balance_to_pay') }}</small></b></td>
            <td class="text-center tdDescription"></td>
          </tr>
          <tr>
            <th class="text-center thDescriptionHead">O.P</th>
            <th class="text-center thDescriptionHead">Empleado</th>
            <th class="text-center thDescriptionHead">Cédula</th>
            <th class="text-center thDescriptionHead">Cargo</th>
            <th class="text-center thDescriptionHead">Devengado<br/>Matrículas</th>
            <th class="text-center thDescriptionHead">Otros<br/>Devengado</th>
            <th class="text-center thDescriptionHead">Total<br/>Devengado</th>
            <th class="text-center thDescriptionHead">Débitos</th>
            <th class="text-center thDescriptionHead">Créditos</th>
            <th class="text-center thDescriptionHead">Neto a Pagar</th>
            <th class="text-center thDescriptionHead">Saldo Pasa</th>
            <th class="text-center thDescriptionHead">Est.</th>
          </tr>
          </thead>
          <tbody class="text-center tableBody">
          <tr  v-for="(resumePayment, keyResumePaymentContent) in resumePaymentContent" :key="'tr_'+keyResumePaymentContent">
            <td class="tdDescription">{{resumePayment.employee_id}}</td>
            <td class="tdDescription">{{resumePayment.employee}}</td>
            <td class="tdDescription">{{resumePayment.employee_dni}}</td>
            <td class="tdDescription">{{resumePayment.position}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.total)}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.others_values)}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.accrued)}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.credits)}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.debits)}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.net_to_pay)}}</td>
            <td class="tdDescription">{{formatMoney(resumePayment.balance_to_pay)}}</td>
            <td class="tdDescription">I</td>
          </tr>
          </tbody>

        </table>
        <br/>
        <br/>
      </div>
    </div>

    <div class="card" v-if="totalsResumePayments.length>0">
      <div class="table-responsive">
        <table class="table table-hover" style="width: 100%">
          <tbody>
          <tr>
            <td class="text-center" colspan='4'>&nbsp;</td>
            <td class="text-center">Devengado<br/>Matrículas</td>
            <td class="text-center">Otros<br/>Devengado</td>
            <td class="text-center">Total<br/>Devengado</td>
            <td class="text-center">Débitos</td>
            <td class="text-center">Créditos</td>
            <td class="text-center">Neto a Pagar</td>
            <td class="text-center">Saldo Pasa</td>
          </tr>
          <tr>
            <td colspan='4'><b>TOTAL TRANSFERENCIA</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'total')}}</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'others_values')}}</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'accrued')}}</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'credits')}}</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'debits')}}</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'net_to_pay')}}</b></td>
            <td class="text-center"><b>{{sumTotalResumePayments(totalsResumePayments,'balance_to_pay')}}</b></td>
          </tr>
          <tr>
            <td colspan='12'><b>TOTAL REGISTROS &nbsp; &nbsp;{{(totalsResumePayments.length)}}</b></td>
          </tr>
          </tbody>
        </table>
        <br/>
        <br/>
      </div>
    </div>


  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import catalogService from "../../core/service/catalog.service";
import moment from "moment";
import service from "../service/report.service";
import FileSaver from 'file-saver';

export default {
  mixins: [bouncer],
  name: "ResumePaymentReport",
  data() {
    return {
      loading: false,
      date_end: null,
      date_start: null,
      date_end_max: null,
      subsidiary: null,
      country: null,
      countries: [],
      resumePayments:[],
     totalsResumePayments:[],
      service: service('resume-payments'),
      selectedMonth: null,
      selectedWeek: null,
    }
  },
  mounted() {
    if (this.canAccess('rp_resume_payments')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);

      const date = new Date();
      this.date_end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_end_max = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_start = new Date(date.getFullYear(), date.getMonth(), 1);

      catalogService.get(`select/countries/subsidiaries-mw`).then(response => {
        this.countries = response.data;
        this.loading = false;
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      });

    }
  },
  methods: {
    formatMoney(valueToTransform) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(valueToTransform);
    },
    sumTotalResumePayments(arrayToEvaluate,keyToSum){
      let initialValue=0;
     return this.formatMoney(arrayToEvaluate.reduce(function (total, currentValue) {
        return total + currentValue[keyToSum];
      }, initialValue));
    },
    formatDate(dat) {
      return moment(dat).format('YYYY-MM-DD');
    },
    filter() {
      this.loading = true;
      this.getCountries()
    },
    getCountries() {
      catalogService.get(`select/countries/subsidiaries-mw`).then(response => {
        this.countries = response.data;
        this.loading = false;
      });
    },
    exportReport(command) {
      if (this.country == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.service.getResumePayments({
        date_start: (this.selectedMonth?.id==null && this.selectedWeek?.id==null)?moment(this.date_start).format("YYYY-MM-DD"):null,
        date_end:  (this.selectedMonth?.id==null && this.selectedWeek?.id==null)?moment(this.date_end).format("YYYY-MM-DD"):null,
        month: this.selectedMonth?.id,
        week: this.selectedWeek?.id,
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        export: command
      })
          .then(x => {
            if (command == 'PDF') {
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            } else {
              this.loading = false;
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        this.loading = false;
        let error = this.$utils.formatError(err.response.data);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })

    },
    getData() {
      if (this.country == null && this.isAdmin) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
      } else {
        if ((!moment(this.date_start).isValid() || !moment(this.date_end).isValid())&& (this.selectedWeek==null && this.selectedMonth==null)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Los campos de fecha son obligatorios para el reporte',
            life: this.$utils.toastLifeTime()
          });
        } else {
          if ((moment(this.date_start) > moment(this.date_end)) && (this.selectedWeek==null && this.selectedMonth==null)) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El campo fecha hasta debe ser mayor o igual que el campo fecha desde',
              life: this.$utils.toastLifeTime()
            });
          } else {
            this.loading = true;
            this.itemsTotal = []
            this.totalsResumePayments=[];
            this.resumePayments=[];
            this.service.getResumePayments({
              date_start: (this.selectedMonth?.id==null && this.selectedWeek?.id==null)?moment(this.date_start).format("YYYY-MM-DD"):null,
              date_end:  (this.selectedMonth?.id==null && this.selectedWeek?.id==null)?moment(this.date_end).format("YYYY-MM-DD"):null,
              month: this.selectedMonth?.id,
              week: this.selectedWeek?.id,
              country: this.country?.id,
              subsidiary: this.subsidiary?.id
            }).then(x => {
              this.loading = false;
              if (x.data.resumePayments.length === 0) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No hay registros por visualizar con los valores escogidos',
                  life: this.$utils.toastLifeTime()
                });
              } else {
               this.resumePayments=x.data.resumePayments
               this.totalsResumePayments=x.data.totalsResumePayments
              }

            }).catch(err => {
              this.loading = false;
              const message = err.response.data;
              let error = this.$utils.formatError(message);
              this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
            })
          }
        }
      }

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      var subsidiaries = this.country ? this.country.subsidiaries : [];


      var elements = [];
      if (subsidiaries.length > 0) {
        var obj = {
          id: null,
          employee_id: 0,
          city_id: subsidiaries[0].city_id,
          name: "Todas las Filiales",
          created_at: null,
          deleted_at: null,
          laravel_through_key: subsidiaries[0].laravel_through_key,
          updated_at: null
        };
        elements = [obj, ...subsidiaries]
      }
      return elements;
    },
    months() {
      const result = this.country ? this.country.months : [];
      if (result.length === 0 && this.country) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen meses asociados al país',
          life: this.$utils.toastLifeTime()
        });
      }
      return result;
    },
    weeks() {
      const result = this.selectedMonth ? this.selectedMonth.weeks : [];
      if (result.length === 0 && this.selectedMonth) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen semanas asociadas al mes',
          life: this.$utils.toastLifeTime()
        });
      }
      return result;
    }
  },
  watch: {
    date_start: function (value) {
      this.selectedMonth = value!=null?null: this.selectedMonth;
      this.selectedWeek = value!=null?null: this.selectedWeek;
    },
    date_end: function (value) {
      this.selectedMonth = value!=null?null: this.selectedMonth;
      this.selectedWeek = value!=null?null: this.selectedWeek;
    },
    selectedWeek: function (value) {
      this.date_start = value!=null?null: this.date_start;
      this.date_end = value!=null?null: this.date_end;
    },
    selectedMonth: function (value) {
      this.date_start = value!=null?null: this.date_start;
      this.date_end = value!=null?null: this.date_end;
      this.selectedWeek=null;
    },
  }
}
</script>

<style>

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

.thReport {

  padding: 2px !important;
  border-width: 1px !important;
}

.tdReport {
  padding: 2px !important;
  border-width: 1px !important;
}

div.tableC > div > div > table {
  table-layout: auto !important;
}

.df {
  margin-left: 3px !important;
  margin-right: 4px !important;

}

span.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-inputwrapper-filled {
  height: 35px !important;
}


.table-responsive {
  overflow-x: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.thDescriptionHead {
  background: #f8f9fa !important;
}

.tdDescription {
  padding: 4px !important;
  text-align: center;
}

.p-dropdown-label {
  text-align: left;
}
</style>
